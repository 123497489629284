import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { axiosInstance } from "../helpers/axiosConfig";
import { AlertContext } from "./AlertFlag";
import { Sheet, Box, Typography, Divider, Button, Input, RadioGroup, Radio } from "@mui/joy";
import { convertTimeString } from "../helpers/TimeConversionHelper";
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { formatNoteName, nonJsonToJson, checkExistingTitles } from "../helpers/TextFormat";
import { copyToClipboard } from "../helpers/CopyToCliboard";
import NavBlocker from "./NavBlocker";
import ConfirmEmailModal from "./ConfirmSendEmail";
import { downloadText, downloadTextoTxt } from "../helpers/DownloadAndFormat";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { NoteToPDF } from "../helpers/DownloadNotePDF";
import { getFullName } from "../helpers/AuthBarrier";


function PastVisitExpandedNew() {
    const { visitId } = useParams();
    const { addAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const noteRef = useRef();
    const transcriptRef = useRef();

    const [note, setNote] = useState(null);
    const [noteTitle, setNoteTitle] = useState('');
    const [editing, setEditing] = useState(false);
    const [editingTranscript, setEditingTranscript] = useState(false);
    const [emailTrigger, setSendEmail] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState('pdf');
    const contextPackage = useOutletContext();

    const [accordionExpanded, setAccordionExpanded] = useState(false);

    let noteTitles = [];
    let noteParts = [];
    let noteBodyReferences = [];

    var userFullName = useRef(getFullName());

    const createNoteText = () => {
        let noteText = '';
        if (checkExistingTitles(renderText())) {
            noteTitles.forEach( (title, index) => {
                index === 0 ? noteText += `**${title}**\n` : noteText += `\n\n**${title}**\n `;
                noteText += noteBodyReferences[index].current.innerText;
            })
        } else {
            noteText = noteRef.current.textContent.trim();
        }

        return noteText;
    }

    const handleCancelSaveNote = () => {
        setEditing(false);
        // If special refs is empty, this won't run anyways so i think it's fine
        for(let index = 0; index < noteBodyReferences.length; index++) {
            if (noteBodyReferences[index].current) {
                noteBodyReferences[index].current.textContent = noteParts[index];
            }
        }
    }

    const handleDeleteNote = async() => {
        await axiosInstance.delete(`/api/v1/visit/${visitId}`).then(response => {
            addAlert("Deleted!", "success");
            // This seems pretty bad design, contextPackage should be reworked ideally to some sort of identifier for deleting the note.
            contextPackage[0](contextPackage[2], contextPackage[1])
            navigate("/past-visits/");
        }).catch(error => {
            console.log(error);
            addAlert("There was an error deleting your note", "danger");
        })
    }

    const handleSaveNote = async () => {
        setEditing(false);
        setNote(null);
        const request_body = {
            "user_edited_result": {
                "value": createNoteText(),
              },
            "title": noteTitle,
            "user_edited_transcript": {
                "value": transcriptRef.current.innerText.trim(),
            }
        }
        await axiosInstance.put(`/api/v1/visit/${visitId}`, request_body).catch( error => {
            addAlert("There was an error saving your note", "danger");
            openVisit();
        }).then( response => {
            addAlert("Updated and Saved!", "success");
            setNote(response.data);
        });
    }

    const handleCancelTranscript = () => {
        setEditingTranscript(false);

        if (transcriptRef.current) {
            transcriptRef.current.textContent = renderTranscript()
        }
    }

    const handleRegenerateTranscript = async () => {
        setEditingTranscript(false);
        const request_body = {
            "title": noteTitle,
            "user_edited_transcript": {
                "value": transcriptRef.current.innerText.trim(),
            }
        }

        await axiosInstance.put(`/api/v1/visit/${visitId}`, request_body).catch( error => {
            addAlert("There was an error regenerating your note.", "danger");
            return;
        })
        
        await axiosInstance.post(`/api/v1/visit/${visitId}/regenerate_note`, {
            "transcript_source": 'user_edit',
        }).then(response => {
            addAlert("Regeneration has been queued!", "success");
            contextPackage[0](contextPackage[2], contextPackage[1]);
            setNote(null);
            navigate("/past-visits/");
        }).catch(error => {
            addAlert("There was a problem regenerating your note", "danger");
        })

        setEditingTranscript(false);
    }


    const openVisit = async () => {
        await axiosInstance.get(`/api/v1/visit/${visitId}`).then(response => {
            if (response.status !== 200) {
                addAlert("We couldn't open this specific note at this time", "danger");
            } else {
                setNote(response.data);
                setNoteTitle(response.data['title']);
            }
        }).catch( error => {
            addAlert("We couldn't open this specific note at this time", "danger");
        })
    }

    const setRefs = (index) => {
        noteBodyReferences[index] = createRef();
    }

    const setTitles = (titles) => {
        noteTitles = titles;
    }

    const setFormattableParts = (parts) => {
        noteParts = parts;
    }

    const renderText = () => {
        if (note['user_edited_result']['value'].length <= 2) {
            return note['generate_note']['generated_result']['value'];
        } else {
            return note['user_edited_result']['value'];
        }
    }

    const renderTranscript = () => {
        if (note['user_edited_transcript']['value'].length <= 2) {
            return note['transcription']['result']['transcript']
        } else {
            return note['user_edited_transcript']['value']
        }
    }

    const toggleAccordionExpansion = () => {
        setAccordionExpanded(!accordionExpanded)
    }

    const sendEmail = async () => {
        await axiosInstance.post(`/api/v1/visit/${visitId}/send_email`).then(response => {
            addAlert("Visit details sent to email", "success");
        }).catch(error => {
            addAlert("There was a problem sending visit details to email", "danger");
        })
    }

    const handleChangeDownload = (event) => {
        setDownloadFormat(event.target.value);
      };

    useEffect(() => {
        openVisit();
    }, [visitId])

    return <> 
        {note ? <Sheet
        variant="outlined"
      sx={{
        width: '93%',
        height: "92vh",
        m: 1,
        p: 1,
        borderRadius: 'sm',
      }}
    >
    <NavBlocker dirty={editing || editingTranscript}/>
    <ConfirmEmailModal open={emailTrigger} closePopupExternal={() => setSendEmail(false)} sendEmailExternal={sendEmail}/>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1.5, }}>
        <Input value={noteTitle} onChange={(event) => setNoteTitle(event.target.value)}
            textColor="text.primary" variant="plain" size="lg" sx={{ fontSize: "1.5rem", width: '35%'}}>
                {noteTitle}
        </Input>
        <Box
          sx={{ display: 'flex', height: '32px', flexDirection: 'row', gap: 1, alignSelf: "center"}}
        >
            <Button variant="outlined" color="primary" sx={{ color: "white", height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", px: 2, '&:hover': { backgroundColor: 'var(--main-blue-hover)'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} startDecorator={<ContentCopyIcon/>} onClick={() => {
                copyToClipboard(createNoteText())
                addAlert("Copied!", "success")
                }}>
                Copy
            </Button>
            <Button variant="outlined" color="primary" sx={{ color: "white", height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", px: 2, '&:hover': { backgroundColor: 'var(--main-blue-hover)'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} startDecorator={accordionExpanded ? <VisibilityOffIcon/> : <VisibilityIcon/>} onClick={toggleAccordionExpansion}>
                {accordionExpanded ? "Hide" : "Transcript"}
            </Button>
            <Button variant="outlined" color="neutral" sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} startDecorator={<IosShareIcon/>} onClick={() => setSendEmail(true)}>
                Email
            </Button>
            <Button variant="outlined" color="danger" sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", borderColor: "red"}} startDecorator={<DeleteOutlineIcon/>} onClick={() => handleDeleteNote()}>
                Delete
            </Button>
        </Box>
    </Box>
    <Box sx={{ml: '1rem', mb: 1, display: 'flex', gap: 1.5}}>
        <Typography level="title-sm" textColor="text.primary" alignSelf="center">
            {formatNoteName(note['note_type'])}
        </Typography>
        <Typography level="title-sm" textColor="text.tertiary" alignSelf="center">
            {convertTimeString(note['created_at'])}
        </Typography>
    </Box>
    <Divider sx={{'--Divider-thickness': '2px'}}/>
    <div style={{ display: 'flex'}}>
    <Box sx={{flex: '5.5', overflowY: 'auto', ml: '1rem', py: 1, height: "78.5vh"}}>
        <Box sx={{ display: 'flex', py: 1, gap: '0.75rem'}}>
            <Typography level='body-lg' alignSelf='center'> Note: </Typography>
            <Button variant="outlined" color="neutral" sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} startDecorator={editing ? <ClearIcon/> : <EditIcon/>} onClick={() => {editing ? handleCancelSaveNote() : setEditing(true)}}>
                {editing ? "Cancel": "Edit"}
            </Button>
            <Button variant="outlined" color="success" disabled={!editing} sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} startDecorator={<CheckIcon/>} onClick={() => handleSaveNote()}>
                Save
            </Button>
            {downloadFormat === 'pdf' ? <Button variant="outlined" color="neutral" startDecorator={<FileDownloadOutlinedIcon/>} sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} 
            >
                <PDFDownloadLink document={<NoteToPDF title={`${noteTitle}`} note={note} createdBy={userFullName.current} noteType={note.note_type}/>}
                    fileName={noteTitle}
                > 
                    Download
                </PDFDownloadLink>
            </Button> : <Button variant="outlined" color="neutral" startDecorator={<FileDownloadOutlinedIcon/>} sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} 
                onClick={() => {downloadTextoTxt(renderText(), noteTitle, note.created_at)}}
            >
                    Download
            </Button>}
            <Box sx={{display:"flex", flexDirection: 'column', gap: 0.5, alignSelf:'center'}}>
                <Radio checked={downloadFormat === 'pdf'} onChange={handleChangeDownload} value={'pdf'} label='.pdf' size="sm"/>
                <Radio checked={downloadFormat === 'txt'} onChange={handleChangeDownload} value={'txt'} label='.txt' size="sm"/>
            </Box>
        </Box>
        <Box sx={{whiteSpace: 'pre-wrap'}}>
            {checkExistingTitles(renderText()) ? nonJsonToJson(renderText(), setTitles, setRefs, noteBodyReferences, editing, setFormattableParts) : <Typography ref={noteRef} contentEditable={editing} suppressContentEditableWarning={true} padding="10px" > {renderText()} </Typography>}
        </Box>
    </Box>
    {accordionExpanded && <Divider orientation="vertical" flexItem sx={{'--Divider-thickness': '2px'}}/>}
    <Box sx={{flex: '4.5', overflowY: 'auto', whiteSpace: 'pre-wrap', display: accordionExpanded ? "block" : "none", height: "78.5vh"}} >
        <Box sx={{ ml: '1rem', py: 1 }}>
            <Box sx={{display: 'flex', gap: "0.75rem"}}>
                <Typography level='body-lg' alignSelf="center"> Transcript: </Typography>
                <Button variant="outlined" color="primary" sx={{ color: "white", height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex", px: 2, '&:hover': { backgroundColor: 'var(--main-blue-hover)'}, '&:active': {backgroundColor: 'var(--light-blue-button)'}}} startDecorator={accordionExpanded ? <VisibilityOffIcon/> : <VisibilityIcon/>} onClick={toggleAccordionExpansion}>
                    {accordionExpanded && "Hide"}
                </Button>
                <Button variant="outlined" color="neutral" sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} startDecorator={editing ? <ClearIcon/> : <EditIcon/>} onClick={() => editingTranscript ? handleCancelTranscript() : setEditingTranscript(true)}>
                    {editingTranscript ? "Cancel": "Edit"}
                </Button>
                <Button variant="outlined" color="success" disabled={!editingTranscript} sx={{ backgroundColor: 'white', height: 'fit-content', alignSelf: 'center', p: 1, width: "fit-content", display: "inline-flex"}} startDecorator={<CheckIcon/>} onClick={() => handleRegenerateTranscript()}>
                    Save
                </Button>
            </Box>
            <Typography level="body-sm" marginTop="10px" px="10px" ref={transcriptRef} contentEditable={editingTranscript} suppressContentEditableWarning={true}>
                {renderTranscript()}
            </Typography>
        </Box>
    </Box>
    </div>
    </Sheet> : <div> Loading.... </div>}
    </>
}

export default PastVisitExpandedNew;