import React, { Component } from "react";
import PastVisitCard from "../../components/PastVisitCard";
import { AlertContext } from "../../components/AlertFlag";
import { Box, Button, List, listItemButtonClasses } from "@mui/joy";
import Pagination from "../../components/Pagination";
import { axiosInstance } from "../../helpers/axiosConfig";
import { calculateAndConvertTime } from "../../helpers/TimeConversionHelper";
import AdaptResize from "../../layout/ResizeWrapper";
import { Outlet } from "react-router-dom";

const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };


class PastVisits extends Component{
    static contextType = AlertContext
    constructor() {
        super()
        this.state = {
            visitsGenerated: [],
            visitSelectedId: null,
            dateRange: '3 months',
            savingVisit: false,
            page: 1,
            totalVisits: 0,
        }
        this.deletingID = null;
        this.fetchInterval = null;
        this.currentDateHeader = null;
    }

    componentDidMount() {
        this.fetchVisits(this.state.dateRange, this.state.page);

        this.fetchInterval = setInterval( () => {
            this.fetchVisits(this.state.dateRange, this.state.page);
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.fetchInterval);
    }

    renderFilterContent = async (dateRange) => {
        this.setState({
            dateRange: dateRange,
        })
        await this.fetchVisits(dateRange, this.state.page);
    }


    fetchVisits = async (timeRange, page) => {
        let visits = [];
        let total = 0;
        await axiosInstance.get('/api/v1/visit', { params: {
            'from_time': calculateAndConvertTime(timeRange),
            'to_time': calculateAndConvertTime('current'),
            'sort': '-created_at',
            'page': page,
            'size': 25,
        }}).then(response => {
            visits = response.data['items'];
            total = response.data['total'];
        }).catch(error => {
            this.context.addAlert("There was an error retrieving your visits", "danger");
        })
        this.setState({
            visitsGenerated: visits,
            totalVisits: total,
        })
    }

    parseAndShow(note) {
        try {
            let analysis = note['user_edited_result']['value'];
            if (analysis.length <= 2) {
                analysis = note['generate_note']['generated_result']['value']
            }
            if (analysis.length > 40) {
                return `${analysis.slice(0, 40)}...`;
            } else {
                return `${analysis}`;
            }
        } catch(error) {
            console.log(error)
        }
    }

    handlePageChange = (value) => {
        this.fetchVisits(this.state.dateRange, value);
        this.setState({
            page: value,
        })
    }

    openSpecificVisit = (id) => {
        this.setState({
            visitSelectedId: id
        })
    }

    handleStillGenerating = () => {
        this.context.addAlert("This note is generating. Refresh the page to get an updated status!", "danger");
    }

    render() {
        return (
            <div>
                <AdaptResize>
                    <div className="row-class">
                        <div className="visit-cards">
                            <div className="row-class-filter">
                                <Button onClick={() => this.renderFilterContent('last 7 days')} className='filter-buttons'> Last 7 days</Button>
                            </div>
                            <List sx={{
                                [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
                                borderLeft: '2px solid',
                                borderLeftColor: 'var(--joy-palette-primary-outlinedBorder)',
                                },
                                padding: '0px',
                                width: '100%',
                            }}>
                            {(this.state.visitsGenerated.map((item, index) => {
                                const nowDate = new Date()
                                const yesterday = new Date()
                                yesterday.setDate(nowDate.getDate() - 1);
                                const date = new Date(item['created_at']);
                                let toAddHeader = date.toLocaleString("en-US", dateOptions)

                                if (nowDate.toDateString() === date.toDateString()) {
                                    toAddHeader = "Today"
                                } else if (yesterday.toDateString() === date.toDateString()) {
                                    toAddHeader = "Yesterday"
                                }

                                if (this.currentDateHeader !== toAddHeader) {
                                    this.currentDateHeader = toAddHeader
                                    return <>
                                        <Box sx={{display: 'flex', p: 1, backgroundColor: "#EDF5FD"}}>
                                            {toAddHeader}
                                        </Box>
                                        <PastVisitCard note={item} openVisit={this.openSpecificVisit} selected={this.state.visitSelectedId} key={item['id']}/>
                                    </>
                                } else {
                                    return <PastVisitCard note={item} openVisit={this.openSpecificVisit} selected={this.state.visitSelectedId} key={item['id']}/>
                                }
                            })
                            )}
                            </List>
                            {this.state.visitsGenerated.length > 0 && <Pagination count={Math.ceil(this.state.totalVisits / 25)} page={this.state.page} onChange={this.handlePageChange} />}
                            <div id="seperator"> &nbsp; <br/> &nbsp;</div>
                        </div>
                        <div className="visit-view">
                            <Outlet context={[this.fetchVisits, this.state.page, this.state.dateRange]}/>
                        </div>
                    </div>
                </AdaptResize>
            </div>
        )
    }




}

export default PastVisits;