import React, { useEffect, useState } from "react";
import "../../styling/LoginView.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { broadcastEvent } from "../../helpers/AuthBarrier";
import { Box, IconButton, FormControl, FormLabel, Button, Typography, Link, Divider } from "@mui/joy";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LandingText from "../../components/joy/LandingText";
import FloatingLabelInput from "../../components/joy/FloatingInput";
import { authGuard } from "../../helpers/AuthBarrier";

function LoginView() {
    const navigate = useNavigate();
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            if (await authGuard()) {
                navigate('/new-visit');
            }
        }
        checkUser();
    }, []);

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    }

    const setViewPasswordChange = (value) => {
        setViewPassword(value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const loginRequest = {
            "email": emailValue.toLowerCase(),
            "password": passwordValue,
        }
        console.log(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/login`);
        await axios.post(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/login`, loginRequest,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user_id);
            if (response.data.force_update_password) {
                navigate('/reset-password');
            } else {
                broadcastEvent('localStorageChange', { key: 'token', value: response.data.token });
                navigate('/new-visit');
            }
        }).catch(error => {
            setErrorMessage("Incorrect email or password.")
        });
    };

    const goBack = () => {
        navigate('/');
    }

    const goForgotPassword = () => {
        navigate('/forgot-password');
    }

    const goSignUp = () => {
        navigate('/signup');
    }

    return (
        <Box sx={{ display: "flex", flexGrow: 1, height: "100%"}}>
            <LandingText/>
            <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', [theme.breakpoints.up('sm')]: {
                    width: '30%'
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    alignSelf: 'center',
                }
            })}>
                <IconButton onClick={goBack} sx={{
                        marginTop: '15px',
                        marginLeft: '15px',
                        height: '50px',
                        width: '50px',
                    }}>
                        <ArrowBackIcon />
                </IconButton>
                <form onSubmit={handleSubmit} id="login-form">
                    <FormControl sx={{ alignSelf: 'center', padding: '20px', width: '100%', gap: 2}}>
                        <FormLabel sx={{ fontSize: '1.5rem', fontWeight:"600"}}>Welcome Back!</FormLabel>
                        <Typography> Please enter your sign in details below:</Typography>
                        <FloatingLabelInput label="Email" value={emailValue} onChange={handleEmailChange} type="text"/>
                        <FloatingLabelInput label="Password" value={passwordValue} onChange={handlePasswordChange} type={viewPassword ? "text" : "password"} icon={<IconButton onClick={() => {setViewPasswordChange(!viewPassword)}}>
                            <VisibilityIcon/>
                        </IconButton>}/>
                        {errorMessage && <Typography sx={{ color: "red"}}> {errorMessage} </Typography>}
                        <Link onClick={goForgotPassword} sx={{alignSelf: 'end'}}>Forget Password?</Link>
                        <Button type="submit" sx={{ fontSize: "1rem", padding: '10px'}}> Sign In </Button>
                        <Divider> Don't have an account?&nbsp;<Link onClick={goSignUp} fontSize="sm">Sign Up</Link></Divider>
                    </FormControl>
                </form>
            </Box>
        </Box>
  );
}

export default LoginView