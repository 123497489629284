import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginView from './views/no-auth/LoginView';
import DefaultView from './views/no-auth/DefaultView';
import SignUpView from './views/no-auth/SignupView';
import ProtectedRoute from './helpers/AuthBarrier';
import PastVisits from './views/auth/PastVisits';
import AccountView from './views/auth/AccountView';
import SettingsView from './views/auth/SettingsView';
import ForgotPasswordView from './views/no-auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPassword';

import React from 'react';
import LogOutComponent from './views/auth/LogOutComponent';
import NewVisitView from './views/auth/NewVisit';
import UploadNewVisitView from './views/auth/UploadNewVisit';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import Layout from './layout/Layout';
import { AlertProvider } from './components/AlertFlag';
import PastVisitExpandedNew from './components/PastVisitExpanded2';

const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    },
    light: {
      palette: {
        primary: {
          50: '#EDF5FD',
          100: '#ELE6FF',
          200: '#b2bbe3',
          300: '#a0b0f7',
          400: '#8194e6',
          500: '#7b92f5ff',
          600: '#5774f0',
          700: '#cbd1ecff',
          800: '#2a3041ff',
          900: '#2a3041ff',
          plainColor: '#7b92f5ff',
        },
      }
    }
  },
  typography: {
    fontFamily: 'Questrial',
  },
  components: {
    JoyDialogTitle:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyButton:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
          backgroundColor: '#5774f0',
          padding: '10px',
          paddingLeft: '20px',
          fontSize: '1rem',
          display: 'inline-block',
        }
      }
    },
    JoyTypography:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyInput:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoySelect:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
          height: "fit-content",
          padding: "10px",
          paddingLeft: '18px',
        }
      }
    },
    JoyOption:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyListItemButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Questrial',
          marginTop: '1px',
          marginBottom: '1px',
          paddingTop: '14px',
          paddingBottom: '14px',
          borderRadius: '12px',
        }
      }
    },
    JoyList: {
      styleOverrides: {
        root: {
          flexGrow: 0
        }
      }
    },
    JoyFormLabel:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyFormHelperText:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    JoyLink:{
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color &&
            ownerState.color !== "inherit" && {
              color: `var(--joy-palette-primary-${ownerState.color})`
            }),
          margin: "var(--Icon-margin)",
          ...(ownerState.fontSize &&
            ownerState.fontSize !== "inherit" && {
              fontSize: `var(--Icon-fontSize, var(--joy-fontSize-${ownerState.fontSize}))`
            }),
          ...(ownerState.instanceFontSize &&
            ownerState.instanceFontSize !== "inherit" && {
              "--Icon-fontSize": `var(--joy-fontSize-${ownerState.instanceFontSize})`
            })
        })
      }
    },
    JoyAlert: {
      styleOverrides: {
        root:{
          fontFamily: 'Questrial',
        }
      }
    }
  }
})
const router = createBrowserRouter([
  { path: '/', element: <Layout/>, children: [
    { index: true, element: <DefaultView />},
    { path: 'login', element: <LoginView /> },
    { path: 'signup/:referralCode?', element: <SignUpView /> },
    { path: 'forgot-password', element: <ForgotPasswordView /> },
    { path: 'reset-password', element: <ProtectedRoute><ResetPasswordView /></ProtectedRoute> },
    { path: 'new-visit', element: <ProtectedRoute><NewVisitView autoStart={false} key="default-visit" /></ProtectedRoute> },
    { path: 'prerecorded-visit', element: <ProtectedRoute><UploadNewVisitView /></ProtectedRoute> },
    { path: 'past-visits', element: <ProtectedRoute><PastVisits /></ProtectedRoute>, children: [
      { path: ':visitId', element: <PastVisitExpandedNew/> }
    ]},
    { path: 'account', element: <ProtectedRoute><AccountView /></ProtectedRoute> },
    { path: 'settings', element: <ProtectedRoute><SettingsView /></ProtectedRoute> },
    { path: 'logout', element: <ProtectedRoute><LogOutComponent /></ProtectedRoute> },
    { path: 'new-visit-auto', element: <ProtectedRoute><NewVisitView autoStart={true} key="auto-visit" /></ProtectedRoute> },
  ]},
])

function App() {

  return (
    <CssVarsProvider theme={theme}>
      <AlertProvider>
        <RouterProvider router={router}/>
      </AlertProvider>
    </CssVarsProvider>
  );
}

export default App;
