import React, { useContext, useEffect, useState } from "react";
import "../../styling/SignUp.css";
import { Box, Button, Checkbox, FormHelperText, IconButton, Select, Typography, Option, LinearProgress } from "@mui/joy";
import LandingText from "../../components/joy/LandingText";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AlertContext } from "../../components/AlertFlag";
import FloatingLabelInput from "../../components/joy/FloatingInput";
import TOSModal from "../../components/TOSModal";

function SignUpView() {
    const { addAlert } = useContext(AlertContext);
    const { referralCode } = useParams();
    const navigate = useNavigate();
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [agreeValue, setAgreeValue] = useState(false);
    const [region, setRegion] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [referral, setReferral] = useState('')

    const [pageStep, setPageStep] = useState(1);

    const [confirmPassword, setConfirmPassword ] = useState('');
    const [showPopup, setPopup ] = useState(false);
    const [confirmName, setConfirmName] = useState('');

    const [errorMessage, setErrorMessage] = useState(null);
    const [signatureError, setSignatureError] = useState('');

    const handleRegionChange = (event, region) => {
        setRegion(region);
    }

    const handleFirstNameChange = (event) => {
        setFirstNameValue(event.target.value);
        if (agreeValue) {
            setAgreeValue(false);
        }
    };

    const handleLastNameChange = (event) => {
        setLastNameValue(event.target.value);
        if (agreeValue) {
            setAgreeValue(false);
        }
    }

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
        if (agreeValue) {
            setAgreeValue(false);
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (agreeValue) {
            setAgreeValue(false);
        }
    };

    const setViewPasswordChange = (value) => {
        setViewPassword(value);
    }

    const setViewConfirmPasswordChange = (value) => {
        setViewConfirmPassword(value);
    }

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
        if (agreeValue) {
            setAgreeValue(false);
        }
    };

    const handleCheckboxClick = () => {
        setShowPopup(true);
    }

    const handleConfirmNameChange = (event) => {
        setConfirmName(event.target.value);
    }

    function setShowPopup(event) {
        setPopup(event);
    }

    useEffect(() => {
        if (errorMessage) {
            setErrorMessage(null);
        }
    }, [firstNameValue, lastNameValue, passwordValue, emailValue, agreeValue, region])

    useEffect( () => {
        if (referralCode) {
            setReferral(referralCode);
        }
    }, [])


    const handleNextPage = (event) => {
        event.preventDefault();
        const specialCharsRegex = /[^a-zA-Z0-9_]/;
        if (!firstNameValue) {
            setErrorMessage("Please enter your first name");
        } else if (!lastNameValue) {
            setErrorMessage("Please enter your last name");
        } else if (!emailValue) {
            setErrorMessage("Please enter an email");
        } else if (!passwordValue) {
            setErrorMessage("Please enter a password");
        
        } else if(passwordValue.length <= 8) {
            setErrorMessage("Please make sure your password is at least 9 characters");
        } else if(!specialCharsRegex.test(passwordValue)) {
            setErrorMessage("Please make sure your password contains one special character");
        } else if (!emailValue.includes("@")) {
            setErrorMessage("Please enter a valid email");
        } else {
            setPageStep(2);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const specialCharsRegex = /[^a-zA-Z0-9_]/;
        const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
        if (!firstNameValue) {
            setErrorMessage("Please enter your first name");
        } else if (!lastNameValue) {
            setErrorMessage("Please enter your last name");
        } else if (!emailValue) {
            setErrorMessage("Please enter an email");
        } else if (!passwordValue) {
            setErrorMessage("Please enter a password");
        
        } else if(passwordValue.length <= 8) {
            setErrorMessage("Please make sure your password is at least 9 characters");
        } else if(!specialCharsRegex.test(passwordValue)) {
            setErrorMessage("Please make sure your password contains one special character");
        } else if (!emailValue.includes("@")) {
            setErrorMessage("Please enter a valid email");
        } else if(!agreeValue) {
            setErrorMessage("Please agree to the Terms and Conditions");
        } else if (!region){
            setErrorMessage("Please select which region you are currently in")
        }else {
            setPageStep(3);
            const newUser = {
                "full_name": `${firstNameValue} ${lastNameValue}`,
                "job_title": region,
                "emr_ehr_provider": "not in use",
                "email": emailValue.toLowerCase(),
                "password": passwordValue,
                // Temporarily disable mandatory phone number
                "phone_number": (!phoneNumber ? "123-456-1234" : phoneNumber),
                "referral_code": referral,
                "force_update_password": false,
            }
            await axios.post(`${process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_HOST}/api/user`, newUser, 
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                addAlert("Success! You will be redirected to the login page in 5 seconds!", "success");
                setTimeout( () => {
                    navigate('/login');
                }, 5000);
            }).catch(error => {
                setErrorMessage(error.response.data['detail']);
            })
        }
    }

    const handleSignature = (event) => {
        event.preventDefault();

        if (`${firstNameValue} ${lastNameValue}` !== (`${firstNameValue} ${lastNameValue}`)) {
            setSignatureError("Ensure your names are matching");
        } else {
            setShowPopup(false);
            setAgreeValue(true);
        } 
    }

    const goBack = () => {
        if (pageStep >= 2) {
            setPageStep(1);
        } else {
            navigate('/');
        }
    }

    return (<Box sx={{ backgroundColor: "#FFFFFF",display: "flex", justifyContent: "center",  alignItems: "center",  minHeight: "100vh",width: "100vw"}}>
        
        <Box sx={(theme) => ({
                margin: '25px', borderRadius: '30px', display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)',[theme.breakpoints.up('sm')]: {
                    width: '30%'
                }, [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    alignSelf: 'center',
                }, alignItems: 'center',
                paddingBottom: '20px',
                
            })}>
            <IconButton onClick={goBack} sx={{
                    marginTop: '15px',
                    marginLeft: '15px',
                    height: '50px',
                    width: '50px',
                    alignSelf: 'start'
                }}>
                    <ArrowBackIcon />
            </IconButton>
            <Box id="signup-form" sx={{gap: 2}}>
                <Typography
                    level="h1"
                    sx={{alignSelf: 'start'}}
                >
                    Sign Up
                </Typography>
                <Box sx={{ display: 'flex',
                    flexDirection: 'column',
                    width:"100%", 
                    gap: '0.75rem', 
                    }}>
                    <LinearProgress determinate size="sm" value={(pageStep - 1 )* 50}/>
                    {pageStep === 1 && <>
                        <FloatingLabelInput 
                        label="First Name"
                        value={firstNameValue}
                        onChange={handleFirstNameChange}
                        />
                        <FloatingLabelInput 
                            label="Last Name"
                            value={lastNameValue}
                            onChange={handleLastNameChange}

                        />
                        <FloatingLabelInput 
                            label="Email"
                            value={emailValue}
                            onChange={handleEmailChange}
                            type="email"
                        />
                    </>}
                    {pageStep >= 2 && <>
                        <FloatingLabelInput 
                        label="Phone Number(Optional)"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        />
                        <Select placeholder="Select your region" value={region} onChange={handleRegionChange}>
                            <Option value="canada">Canada</Option>
                            <Option value="usa">United States of America</Option>
                            <Option value="other">Other</Option>
                        </Select>
                        <FloatingLabelInput 
                            label="Refferal Code (Optional)"
                            value={referral}
                            onChange={(event) => setReferral(event.target.value)}
                        />
                    </>}
                </Box>
                {pageStep === 1 && <>
                    <Box sx={{ width:"100%"}}>
                        <FloatingLabelInput label="Password" value={passwordValue} onChange={handlePasswordChange} type={viewPassword ? "text" : "password"} icon={<IconButton onClick={() => {setViewPasswordChange(!viewPassword)}}>
                                <VisibilityIcon/>
                            </IconButton>}/>
                        <Typography level="body-sm" sx={{textAlign: 'left', pl: '0.5rem'}}>
                            Ensure your password has:
                        </Typography>
                        <Typography component="li" level="body-sm" sx={{ textAlign: 'left', pl: '1rem', '&:before': { content: '"• "' } }}>
                            at least 9 characters
                        </Typography>
                        <Typography component="li" level="body-sm" sx={{ textAlign: 'left', pl: '1rem', '&:before': { content: '"• "'} }}>
                            a special character
                        </Typography>
                    </Box>
                    
                </>}
                {pageStep >= 2 && <Checkbox 
                    label="I agree to the Terms and Conditions"
                    size="sm"
                    checked={agreeValue}
                    onClick={handleCheckboxClick}
                    sx={{ textDecoration: 'underline' }}
                />}
                {errorMessage && <FormHelperText sx={{color: 'red'}}>
                    {errorMessage} 
                </FormHelperText>}
                <Button onClick={pageStep >= 2 ? handleSubmit : handleNextPage} sx={{ fontSize: "1rem", padding: '10px'}}>{pageStep >= 2 ? "Submit" : "Create Account"}</Button>
                <TOSModal 
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    setAgreeValue={setAgreeValue}
                    name={`${firstNameValue} ${lastNameValue}`}
                    emailValue={emailValue}
                    showSignatureButton={true}
                    confirmName={`${firstNameValue} ${lastNameValue}`}
                    handleConfirmNameChange={handleConfirmNameChange}
                    handleSignature={handleSignature}
                    signatureError={signatureError}
                />
            </Box>
        </Box>
    </Box>);
}

export default SignUpView
