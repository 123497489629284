import React, { useEffect, useState } from "react";
import { getUserID } from "../../helpers/AuthBarrier";
import { convertTimeString } from "../../helpers/TimeConversionHelper";
import "../../styling/AccountAndSettingsView.css";
import "../../styling/LoginView.css";
import { useNavigate } from "react-router-dom";
import BillingView from "./BilllingView";
import SettingsView from "./SettingsView";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { axiosInstance } from "../../helpers/axiosConfig";
import { CircularProgress, Button, Input, Tooltip, Typography, Box, } from "@mui/joy";
import TOSModal from "../../components/TOSModal";

function AccountView() {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true);
    const [ name, setName ] = useState(null);
    const [ email, setEmail] = useState(null);
    const [ updatedAt, setUpdatedAt] = useState('');
    const [showTOS, setShowTOS] = useState(false);
    const [createdAt, setCreatedAt] = useState("");

    const handleShowTOS = (event) => {
        setShowTOS(event);
    }

    const goChangePassword = () => {
        navigate("/reset-password");
    }

    async function getAccountDetails() {
        await axiosInstance.get(`/api/v1/user/${getUserID()}`).then( response => {
            const responseBody = response.data;
            setName(responseBody['full_name']);
            setEmail(responseBody['email']);
            setCreatedAt(responseBody['created_at']);
            setUpdatedAt(convertTimeString(responseBody['updated_at']));
            setLoading(false);
        }).catch( error => {
            console.log("Error")
        })
    }

    useEffect( () => {
        getAccountDetails();
    }) 

    return (
        <Box>
            {loading && (<CircularProgress />)}
            {!loading && (
                <Box className="account-page">
                    <BillingView/>
                    <Box id="outline-it" sx={(theme) => ({
                        display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, backgroundColor: 'rgb(255, 255, 255, 50%)', gap: 2,
                    })}>
                        <Typography level='h2'>Profile</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {xs: 'column', md: 'row'},
                                justifyContent: 'space-between',
                                gap: 2,
                                px: "1rem"
                            }}
                        >
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Name:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={name} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                    <Tooltip title={'Your name cannot be changed as you have signed the terms of service'}> 
                                        <Box>
                                            <HelpOutlineIcon/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box
                                sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                            >
                                <Typography>Email:</Typography>
                                <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                                    <Input variant="outlined" disabled placeholder={email} size="md" sx={{
                                        width: {
                                            xs: "10rem", 
                                            sm: "15vw", 
                                            md: "10vw",  
                                            lg: "15vw"
                                        },
                                    }}/>
                                    <Tooltip title={'Your email cannot be changed as you have signed the terms of service'}> 
                                        <Box>
                                            <HelpOutlineIcon/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: {xs:'center', md: 'left'}
                            }}>
                                <Typography>
                                    Last updated at: {updatedAt}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-evenly'}}
                        >
                            <Button 
                                variant="plain"
                                onClick={() => {
                                    handleShowTOS(true)
                                }}
                                sx={{
                                    width: "30vw",
                                    fontWeight: "normal",
                                    minHeight: "2.5rem",
                                    backgroundColor: 'var(--light-grey)',
                                    color: 'var(--main-blue)'
                                }}
                            > 
                                VIEW TERMS OF SERVICE
                            </Button>
                            <Box id="seperator"/>
                            <Button 
                                variant="plain"
                                onClick={() => {
                                    goChangePassword();
                                }} 
                                sx={{
                                    width: "30vw",
                                    fontWeight: "normal",
                                    minHeight: "2.5rem",
                                    backgroundColor: 'var(--light-grey)',
                                    color: 'var(--main-blue)',
                                }}
                            > 
                                CHANGE PASSWORD
                            </Button>
                        </Box>
                        </Box>
                    <SettingsView/>
                    <TOSModal 
                        showPopup={showTOS}
                        setShowPopup={setShowTOS}
                        name={name}
                        showSignatureButton={false}
                        signatureDate={convertTimeString(createdAt)}
                    />
                </Box>
            )}
        </Box>    
    )
}

export default AccountView;