import { Box, Typography, Sheet } from "@mui/joy";
import React from "react";
import "../../styling/LoginView.css";




function LandingText() {

    return <Sheet sx={{ display: { xs:"none", sm:"flex"}, background: "transparent" }}>
        <Box sx={{width:"70%", alignSelf: 'center', marginLeft: '15vw', paddingBottom: "15vh"}}>
            <img src="/assets/doctorandpatient.png" alt="Landing" id="landing-png"/>
            <Typography sx={{fontSize: '2.5rem', fontWeight: '500', fontStyle: { color: "#143866" }, padding: '0px'}}>
                Let {' '}<Typography sx={{fontSize: '2.5rem', fontWeight: '900', fontStyle: { color: "#143866" }, padding: '0px'}}>One</Typography>{''}<Typography sx={{fontSize: '2.5rem', fontWeight: '900', fontStyle: { color: "#5774f0" }, padding: '0px'}}>Chart</Typography> help with the notes
            </Typography>
        </Box>
        </Sheet>
}

export default LandingText;